<template>
      <div>
            <b-collapse v-if="showCollapse == 1" id="collapse-1">
                  <b-card no-body class="mb-2">
                        <b-container fluid>
                              <validation-observer ref="formFilters">
                                    <!-- Form -->
                                    <b-row class="p-1">
                                          <b-col cols="12" md="3">
                                                <validation-provider #default="{ errors }" name="cliente">
                                                      <b-form-group label="Fecha de separación" label-for="customer"
                                                            :class="errors.length > 0 ? 'is-invalid' : null">
                                                            <b-form-datepicker id="example-datepicker"
                                                                  v-model="tableSettings.search.time_separation"
                                                                  class="mb-1" />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                      </b-form-group>
                                                </validation-provider>
                                          </b-col>
                                          <b-col cols="12" md="3">
                                                <validation-provider #default="{ errors }" name="estado">
                                                      <b-form-group label="Número de documento" label-for="status"
                                                            :class="errors.length > 0 ? 'is-invalid' : null" type="number">
                                                            <b-form-input id="search" trim
                                                                  v-model="tableSettings.search.number_document" />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                      </b-form-group>
                                                </validation-provider>
                                          </b-col>
                                          <b-col cols="12" md="2">
                                                <validation-provider #default="{ errors }" name="estado">
                                                      <b-form-group label="Id de separación" label-for="status"
                                                            :class="errors.length > 0 ? 'is-invalid' : null" type="number">
                                                            <b-form-input id="search" trim
                                                                  v-model="tableSettings.search.separation_id" />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                      </b-form-group>
                                                </validation-provider>
                                          </b-col>
                                          <b-col cols="12" md="2">
                                                <validation-provider #default="{ errors }" name="estado">
                                                      <b-form-group label="Estado" label-for="status"
                                                            :class="errors.length > 0 ? 'is-invalid' : null" type="number">
                                                            <v-select :options="status" label="title"
                                                                  v-model="tableSettings.search.status" />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                      </b-form-group>
                                                </validation-provider>
                                          </b-col>
                                          <b-col cols="12" md="2">
                                                <b-button variant="secondary" class="btn-icon btn-block mt-2"
                                                      @click="getTableData()">
                                                      <feather-icon icon="SearchIcon" /> Buscar
                                                </b-button>
                                          </b-col>
                                    </b-row>
                                    <!-- Fin Form -->

                              </validation-observer>
                        </b-container>
                  </b-card>
            </b-collapse>
            <b-card no-body class="mb-0">
                  <div class="m-2">
                        <b-row>
                              <b-col cols="12" md="3" class="">
                                    <label>Mostrar</label>
                                    <v-select v-model="tableSettings.perPage" :options="perPageOptions" :clearable="false"
                                          class="per-page-selector d-inline-block mx-50" />
                                    <label>registros</label>
                              </b-col>
                              <b-col sm="9" class="text-right">
                                    <b-button variant="outline-secondary" v-b-toggle.collapse-1
                                          class="btn-icon float-right ml-2" @click="searchFilter()">
                                          <feather-icon icon="FilterIcon" /> Filtrar
                                    </b-button>
                                    <b-button variant="outline-primary" v-b-toggle.collapse-1 class="btn-icon float-right"
                                          @click="resetFilter()">
                                          <feather-icon icon="AlertCircleIcon" /> Limpiar
                                    </b-button>
                              </b-col>
                        </b-row>
                  </div>
                  <b-table :parametritation="tableSettings.parametritation" ref="refRoleListTable" class="position-relative"
                        :items="dataTable" responsive :fields="tableColumns" primary-key="id"
                        :sort-by.sync="tableSettings.sortBy" show-empty empty-text="No se encontraron datos"
                        :sort-desc.sync="tableSettings.sortDesc">
                        <template #cell(role)="data">
                              <div v-for="(item, index) in data.item.role" :key="index">
                                    <span>{{ item }}</span>
                              </div>
                        </template>
                        <!-- Column: Actions -->
                        <template #cell(status)="data">
                              <!-- <template v-if="item.data.status == 'BOOKED'"> -->
                              <template>
                                    <b-badge variant="primary" v-if="data.item.status == 'BOOKED'">
                                          Separado
                                    </b-badge>
                              </template>
                              <template v-if="data.item.status == 'DELIVERED'">
                                    <b-badge variant="success">
                                          Entregado
                                    </b-badge>
                              </template>
                              <template v-if="data.item.status == 'EXPIRED'">
                                    <b-badge variant="danger">
                                          Vencido
                                    </b-badge>
                              </template>
                        </template>
                        <template #cell(actions)="data">
                              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary"
                                    @click="showModal(data.item.id)" class="btn-icon rounded-circle"
                                    v-b-modal.modal-no-backdrop>
                                    <feather-icon icon="ToggleRightIcon" />
                              </b-button>

                        </template>

                  </b-table>
                  <div class="mx-2 mb-2">
                        <b-row>

                              <b-col cols="12" sm="6"
                                    class="d-flex align-items-center justify-content-center justify-content-sm-start">
                                    <span class="text-muted">Viendo del {{ dataMeta.from }} al {{ dataMeta.to }} de {{
                                          dataMeta.of }} registros</span>
                              </b-col>
                              <!-- Pagination -->
                              <b-col cols="12" sm="6"
                                    class="d-flex align-items-center justify-content-center justify-content-sm-end">

                                    <b-pagination v-model="tableSettings.page" :total-rows="totalRows"
                                          :per-page="tableSettings.perPage" first-number last-number class="mb-0 mt-1 mt-sm-0"
                                          prev-class="prev-item" next-class="next-item">
                                          <template #prev-text>
                                                <feather-icon icon="ChevronLeftIcon" size="18" />
                                          </template>
                                          <template #next-text>
                                                <feather-icon icon="ChevronRightIcon" size="18" />
                                          </template>
                                    </b-pagination>

                              </b-col>

                        </b-row>
                  </div>

            </b-card>
            <b-modal ref="my-modal" hide-footer title="Cambiar de estado" hide-backdrop ok-only no-close-on-backdrop>
                  <div class="d-block text-center">
                        <v-select v-model="form.status" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
                              :options="status" placeholder="Seleccionar" />
                        <b-button class="mt-2" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-success" pill
                              @click="changeStatus()">
                              Guardar
                        </b-button>
                  </div>
            </b-modal>
      </div>
</template>
    
<script>
import { BContainer, BRow, BCol, BCard, BButton, BTable, BPagination, BDropdown, BDropdownItem, BBadge, BModal, BCollapse, BFormGroup, BFormInput, VBToggle, BFormDatepicker } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
export default {
      name: 'Parametritations',
      components: { BContainer, BRow, BCol, BCard, BButton, BTable, BPagination, vSelect, BDropdown, BDropdownItem, BBadge, BModal, BCollapse, ValidationProvider, ValidationObserver, BFormGroup, BFormInput, BFormDatepicker },
      data() {
            return {
                  search: {
                        time_separation: null,
                        number_document: null,
                        separation_id: null,
                        status: null
                  },
                  customers: [],
                  showCollapse: 0,
                  status: [
                        { value: 'BOOKED', title: 'Separado' },
                        { value: 'DELIVERED', title: 'Entregado' },
                        { value: 'EXPIRED', title: 'Vencido' }
                  ],
                  userData: JSON.parse(localStorage.getItem('userData')),
                  formDisabled: false,
                  isAddNewSidebarActive: false,
                  refRoleListTable: null,
                  perPageOptions: [10, 25, 50, 100],
                  searchQuery: '',
                  inputs: [
                        { value: 'id', title: 'Id' },
                        { value: 'name', title: 'Nombre' },
                        { value: 'email', title: 'Correo' },
                        { value: 'email', title: 'Correo' },
                  ],

                  tableColumns: [
                        { key: 'id', label: '#', sortable: true },
                        { key: 'type', label: 'Tipo', sortable: true },
                        { key: 'document', label: 'Documento', sortable: true },
                        { key: 'full_name', label: 'Nombre Completo' },
                        { key: 'cum', label: 'Cum' },
                        { key: 'medicine', label: 'Medicamento' },
                        { key: 'separationTime', label: 'Tiempo De Separación' },
                        { key: 'quantity', label: 'Cantidad' },
                        { key: 'status', label: 'Estado' },
                        { key: 'actions', label: 'Acciones' },
                  ],
                  sortBy: 'id',
                  isSortDirDesc: true,
                  totalRows: 0,
                  dataMeta: {
                        from: 0,
                        to: 0,
                        of: 0,
                  },
                  dataTable: [],
                  tableSettings: {
                        filter: this.$route.params && this.$route.params.id,
                        searchQuery: '',
                        input: '',
                        typeSearch: '',
                        perPage: 10,
                        page: 1,
                        sortBy: 'id',
                        sortDesc: true,
                        parametritation: false,
                        search: {
                              time_separation: null,
                              number_document: null,
                              separation_id: null,
                              status: null
                        },
                  },
                  customersOptions: [],
                  id: this.$route.params && this.$route.params.id,
                  modalId: null,
                  form: {
                        status: null
                  }
            }
      },
      methods: {
            resetFilter() {
                  this.tableSettings.search = {
                        time_separation: null,
                        number_document: null,
                        separation_id: null,
                        status: null
                  }
                  this.getTableData();
            },
            async filter() {
                  this.$http.get('/filter-bookings/' + this.id, { params: this.tableSettings })
                        .then((response) => {
                              if (response.data.code == 200) {
                                    this.$toast({
                                          component: ToastificationContent,
                                          position: "top-right",
                                          props: {
                                                title: response.data.message,
                                                icon: "CheckIcon",
                                                variant: "success",
                                          },
                                    });
                                    this.getTableData();
                              }
                              if (response.data.code == 500) {
                                    this.$toast({
                                          component: ToastificationContent,
                                          position: "top-right",
                                          props: {
                                                title: response.data.message,
                                                icon: "CircleIcon",
                                                variant: "error",
                                          },
                                    });
                              }
                        })
                        .catch((error) => {
                              console.log("error", error);
                        });
            },
            searchFilter() {
                  this.showCollapse = 1
            },
            async changeStatus(status) {
                  this.$http.post('/change-status-bookings/' + this.modalId, this.form)
                        .then((response) => {
                              if (response.data.code == 200) {
                                    this.$toast({
                                          component: ToastificationContent,
                                          position: "top-right",
                                          props: {
                                                title: response.data.message,
                                                icon: "CheckIcon",
                                                variant: "success",
                                          },
                                    });
                                    this.getTableData();
                                    this.$refs['my-modal'].hide()

                              }
                              if (response.data.code == 500) {
                                    this.$toast({
                                          component: ToastificationContent,
                                          position: "top-right",
                                          props: {
                                                title: response.data.message,
                                                icon: "AlertTriangleIcon",
                                                variant: "danger",
                                          },
                                    });
                              }
                        })
                        .catch((error) => {
                              console.log("error", error);
                        });
            },
            showModal(id) {
                  this.$refs['my-modal'].show(id)
                  this.modalId = id
            },
            async getTableData() {
                  this.tableSettings.parametritation = true;
                  await this.$http.get('/list-bookings/' + this.id, { params: this.tableSettings }).then((response) => {
                        this.dataTable = response.data.bookings
                        this.totalRows = response.data.total
                        this.dataMetaCounter()
                  })
                  this.tableSettings.parametritation = false;
            },
            dataMetaCounter() {
                  const localItemsCount = this.dataTable.length
                  this.dataMeta.from = this.tableSettings.perPage * (this.tableSettings.page - 1) + (localItemsCount ? 1 : 0)
                  this.dataMeta.to = this.tableSettings.perPage * (this.tableSettings.page - 1) + localItemsCount
                  this.dataMeta.of = this.totalRows
            },
            async fetchCustomers() {
                  await this.$http.get('/users/customers')
                        .then((response) => {
                              this.customersOptions = response.data.customersOptions
                        })
            },
      },
      created() {
            this.getTableData();
            this.fetchCustomers();
      },
      directives: {
            Ripple,
            'b-toggle': VBToggle
      },
      watch: {
            "tableSettings.sortBy": {
                  handler(val) {
                        this.getTableData()
                  },
            },
            "tableSettings.sortDesc": {
                  handler(val) {
                        this.getTableData()
                  },
            },
            "tableSettings.perPage": {
                  handler(val) {
                        this.getTableData()
                  },
            },
            "tableSettings.searchQuery": {
                  handler(val) {
                        this.getTableData()
                  },
            },
            "tableSettings.page": {
                  handler(val) {
                        this.getTableData()
                  },
            },
      }
}
</script>
    
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>